export class footerBg {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const canvas = document.getElementById('js-footer_bg')
      const ctx = canvas.getContext('2d')
      const devicePixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1
      canvas.width = window.innerWidth * devicePixelRatio
      canvas.height = (540 / 1366) * window.innerWidth * devicePixelRatio
      const bg = new Image()
      if (devicePixelRatio === 2) {
        bg.src = '/img/common/parts/common_bg@2x.jpg'
      } else {
        bg.src = '/img/common/parts/common_bg.jpg'
      }
      const img = new Image()
      img.src = '/img/index/outline_bg_text.svg'
      const position = { x: 0, y: 0 }
      let imgSize = { x: window.innerWidth, y: (570 / 1366) * window.innerWidth }

      if (devicePixelRatio > 1) {
        ctx.scale(devicePixelRatio, devicePixelRatio)
      }

      const draw = () => {
        ctx.globalCompositeOperation = 'source-over'
        ctx.drawImage(bg, 0, 0, canvas.width, canvas.height)
        ctx.globalCompositeOperation = 'destination-in'
        ctx.drawImage(img, position.x, position.y, imgSize.x, imgSize.y)
      }

      const update = () => {
        ctx.canvas.width = window.innerWidth
        ctx.canvas.height = (540 / 1366) * window.innerWidth
        imgSize.x = window.innerWidth
        imgSize.y = (570 / 1366) * window.innerWidth
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        draw()
      }

      //カーソル連動
      window.addEventListener('resize', () => {
        update()
      })

      window.addEventListener('load', function () {
        draw()
      })
    })
  }
}
