export class Accordion {
  constructor() {
    const acoTrgs = document.querySelectorAll('.js-aco_trg')
    const acoTrgArr = Array.prototype.slice.call(acoTrgs, 0)
    const closeList = Array.prototype.slice.call(document.querySelectorAll('.js-aco_close'), 0)
    const Ease = {
      easeInOut: (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
    }
    let duration = 500

    acoTrgArr.forEach(function (target) {
      target.addEventListener('click', (event) => {
        let $this = event.currentTarget
        let content = $this.nextElementSibling

        let contentHeight = content.scrollHeight
        $this.classList.toggle('is-act')

        if ($this.classList.contains('is-act')) {
          content.classList.add('is-show')
          content.style.height = contentHeight + 'px'
        } else {
          content.style.height = null
          content.classList.remove('is-show')
        }
      })
    })

    closeList.forEach(function (target) {
      target.addEventListener('click', (event) => {
        let $this = event.currentTarget
        let parent = $this.parentNode.parentNode.parentNode.parentNode
        let trg = parent.querySelector('.js-aco_trg')
        let trgPosition = window.pageYOffset + trg.getBoundingClientRect().top - 115
        let content = parent.querySelector('.js-aco_content')
        let currentPostion = document.documentElement.scrollTop

        trg.classList.remove('is-act')
        content.style.height = null
        content.classList.remove('is-show')

        //スムーススクロール
        var startTime = performance.now()
        var loop = function (nowTime) {
          var time = nowTime - startTime
          var normalizedTime = time / duration

          if (normalizedTime < 1) {
            window.scrollTo(0, currentPostion + (trgPosition - currentPostion) * Ease.easeInOut(normalizedTime))
            requestAnimationFrame(loop)
          } else {
            window.scrollTo(0, trgPosition)
          }
        }
        requestAnimationFrame(loop)
      })
    })

    //768px以下
    if (window.innerWidth < 768) {
      const acoTabTrgs = document.querySelectorAll('.js-aco_trg_sp')
      const acoTabTrgArr = Array.prototype.slice.call(acoTabTrgs, 0)
      acoTabTrgArr.forEach(function (target) {
        target.addEventListener('click', (event) => {
          let $this = event.currentTarget
          let content = $this.nextElementSibling

          let contentHeight = content.scrollHeight
          $this.classList.toggle('is-act')

          if ($this.classList.contains('is-act')) {
            content.classList.add('is-show')
            content.style.height = contentHeight + 'px'
          } else {
            content.style.height = null
            content.classList.remove('is-show')
          }
        })
      })
    }
  }
}
