import { gsap } from 'gsap'
import Swiper from 'swiper'
import 'swiper/css'

export class WorksSort {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const workTrg = document.querySelector('.js-works_trg')
      const targetArr = Array.prototype.slice.call(document.querySelectorAll('.js-works_item'), 0)
      let itemArr = Array.prototype.slice.call(workTrg.querySelectorAll('.item'), 0)

      const worksSlide = new Swiper('#js-works_slider', {
        slidesPerView: 1,
        slideClass: 'slide',
        spaceBetween: 20,
        loop: false,
        speed: 1000,
        breakpoints: {
          767: {
            slidesPerView: 1.4,
            spaceBetween: 50,
          },
          1025: {
            slidesPerView: 1.8,
            spaceBetween: 100,
          },
        },
      })

      itemArr.forEach(function (el) {
        el.addEventListener('click', (event) => {
          let $this = event.currentTarget
          let val = $this.getAttribute('id')
          itemArr.forEach(function (item) {
            item.classList.remove('is-act')
          })
          $this.classList.add('is-act')
          if (val === 'all') {
            gsap.to('.js-works_item', { opacity: 1, y: 0, display: 'block' })
          } else {
            targetArr.forEach(function (target) {
              let data = target.getAttribute('data-field')
              let result = data.indexOf(val, 0)
              if (result !== -1) {
                gsap.to(target, {
                  opacity: 1,
                  y: 0,
                  display: 'block',
                  onComplete: () => {
                    worksSlide.update()
                  },
                })
              } else {
                gsap.to(target, {
                  opacity: 0,
                  y: 20,
                  display: 'none',
                  onComplete: () => {
                    worksSlide.update()
                  },
                })
              }
            })
          }
        })
      })
    })
  }
}
