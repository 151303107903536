export class VideoStart {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementsByClassName('js-video_start').length == 0) return
      const boxes = document.getElementsByClassName('js-video_start')
      const node = Array.prototype.slice.call(boxes, 0)
      const defaultMargin = '-20%' // ここでデフォルトを書き換え
      const keys = []
      const elements = {}

      const doWhenIntersect = function (entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.play()
            entry.target.classList.add('is-act')
          } else {
            entry.target.pause()
            entry.target.classList.remove('is-act')
          }
        })
      }

      node.forEach(function (box) {
        let key = box.getAttribute('data-margin')
        if (key !== null && key !== '' && key.match(/-*[0-9]*%/)) {
          if (key.replace('%', '') - 0 < -50) {
            key = '-50%'
            box.setAttribute('data-margin', key)
          }
          if (keys.indexOf(key) == -1) {
            keys.push(key)
            elements[key] = []
          }
          elements[key].push(box)
        } else {
          if (keys.indexOf(defaultMargin) == -1) {
            keys.push(defaultMargin)
            elements[defaultMargin] = []
          }
          elements[defaultMargin].push(box)
        }
      })

      keys.forEach(function (key) {
        const observer = new IntersectionObserver(doWhenIntersect, { root: null, rootMargin: key + ' 0px', threshold: 0 })
        elements[key].forEach(function (element) {
          observer.observe(element)
        })
      })
    })
  }
}
