export class Progress {
  constructor() {
    window.addEventListener('load', () => {
      if (window.innerWidth < 768) return
      const progress = document.getElementById('js-progress')
      const pageHeight = document.documentElement.scrollHeight
      window.addEventListener('scroll', () => {
        const windowScroll = window.pageYOffset
        const windowHeight = window.innerHeight
        const scrollUnit = (windowScroll / (pageHeight - windowHeight)) * 100
        if (window.innerWidth < 768) {
          progress.style.width = scrollUnit + '%'
        } else {
          progress.style.height = scrollUnit + '%'
        }
      })
    })
  }
}
