import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export class WorksBg {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      if (window.innerWidth < 768) return
      gsap.registerPlugin(ScrollTrigger)
      gsap.to('.js-scroll_clip', {
        scrollTrigger: {
          trigger: '#js-scroll_trigger',
          scrub: true,
          start: 'top 50%',
        },
        'clip-path': 'inset(8vw 0 6vw 17vw)',
        ease: 'none',
      })
      gsap.to('#js-works_contents', {
        scrollTrigger: {
          trigger: '#js-scroll_trigger',
          scrub: true,
          start: 'bottom 100%',
        },
        y: '+=200',
        ease: 'none',
      })
    })
  }
}
