import { gsap } from 'gsap'
// import { KV } from './Kv'

export class Loading {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const LoadingAnim = () => {
        if (document.querySelector('.c-loading') === null) return
        const tl = gsap.timeline()
        let text = document.querySelector('.c-loading__text')
        let loading = document.querySelector('.c-loading')
        tl.to('.c-loading__logo', { opacity: 1, duration: 0.5, delay: 0.5 })
        tl.fromTo(
          '.c-loading__logo',
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.3,
            onComplete: () => {
              text.classList.add('is-act')
            },
          }
        )
        tl.fromTo(
          '.c-loading__text span',
          { opacity: 0 },
          {
            opacity: 1,
            duration: 0.3,
            delay: 0.7,
            onComplete: () => {
              loading.classList.add('is-hidden')
            },
          }
        )
        tl.to('.c-loading__text span', {
          opacity: 0,
          duration: 0.2,
          delay: 1.1,
          onStart: () => {
            text.classList.add('is-hidden')
          },
        })
        tl.to('.c-loading', { opacity: 0, autoAlpha: 0, display: 'none', duration: 0.8, delay: 0.8 })
        tl.fromTo('.l-header__top', { opacity: 0, y: -10 }, { opacity: 1, y: 0 }, '<')
        tl.fromTo('#js-header_navi', { opacity: 0, y: -10 }, { opacity: 1, y: 0 }, '<')
        tl.fromTo('.p-index_kv__logo', { opacity: 0, x: -20 }, { opacity: 1, x: 0 }, '<')

        tl.fromTo(
          '.p-index_kv',
          { opacity: 0 },
          {
            opacity: 1,
            onStart: () => {
              document.querySelector('.l-header__line').classList.add('is-act')
            },
          },
          '<'
        )
      }

      if (sessionStorage.getItem('loading') === null || sessionStorage.getItem('loading') != 'done') {
        window.addEventListener('load', function () {
          LoadingAnim()
        })
        sessionStorage.setItem('loading', 'done')
      } else {
        document.querySelector('.l-header__line').classList.add('is-act')
        document.querySelector('.l-header__top').style.opacity = 1
        document.querySelector('.p-index_kv__logo').style.opacity = 1
        document.getElementById('js-header_navi').style.opacity = 1
        if (document.querySelector('.c-loading')) {
          document.querySelector('.c-loading').remove()
        }
      }
    })
  }
}
