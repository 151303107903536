import { gsap } from 'gsap'
import { UaCheck } from './Ua'

export class Pointer {
  constructor() {
    const _ua = UaCheck()

    const init = () => {
      if (!_ua.Tablet || !_ua.Mobile) {
        const page = document.body
        const stalker = document.getElementById('js-pointer')
        const hoverElm = document.querySelectorAll('.js-pointer_elm')
        const STALKER_SIZE = 15

        ;({
          init() {
            const self = this
            page.addEventListener('mousemove', self.onMouseMove)
            for (let i = 0; i < hoverElm.length; i++) {
              hoverElm[i].addEventListener('mouseenter', (e) => {
                stalker.classList.add('is-view')
              })
              hoverElm[i].addEventListener('mouseleave', (e) => {
                stalker.classList.remove('is-view')
              })
            }
          },
          onMouseMove(e) {
            gsap.to(stalker, 0.1, {
              x: e.clientX - STALKER_SIZE / 2,
              y: e.clientY - STALKER_SIZE / 2,
              ease: 'none',
            })
          },
        }).init()
      }
    }

    init()
  }
}
