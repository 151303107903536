import { gsap } from 'gsap'

export class Modal {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      const showTrg = document.querySelectorAll('.js-modal_trg')
      const showTrgArr = Array.prototype.slice.call(showTrg, 0)
      const overlay = document.querySelector('.js-modal_overlay')
      const closeTrg = document.querySelectorAll('.js-modal_close')
      const closeTrgArr = Array.prototype.slice.call(closeTrg, 0)
      const html = document.querySelector('html')
      const body = document.querySelector('body')
      const video = document.getElementById('js-modal_video')

      if (overlay) {
        //モーダル表示
        const showModal = (id) => {
          const targetModal = document.querySelector('#' + id)
          html.style.overflow = 'hidden'
          body.style.overflow = 'hidden'
          video.play()
          gsap.to(overlay, 0.4, { autoAlpha: 1, display: 'flex' })
          gsap.to(targetModal, 0.5, {
            delay: 0.2,
            autoAlpha: 1,
            display: 'flex',
          })
        }
        //モーダル非表示
        const closeModal = (id) => {
          const targetModal = document.querySelector('#' + id)
          html.style.overflow = 'visible'
          body.style.overflow = 'visible'
          video.pause()
          gsap.to(overlay, 0.4, { delay: 0.2, autoAlpha: 0, display: 'none' })
          gsap.to(targetModal, 0.5, { autoAlpha: 0, display: 'none' })
        }

        showTrgArr.forEach(function (target) {
          // クリックイベント
          target.addEventListener(
            'click',
            function (event) {
              const target = event.currentTarget
              let modalId
              if (target.getAttribute('data-src')) {
                modalId = 'img_modal'
                let imgSrc = target.getAttribute('data-src')
                let img = document.getElementById('js-img_modal_src').querySelector('img')
                img.setAttribute('src', imgSrc)
              } else {
                modalId = target.getAttribute('data-modal')
              }
              overlay.setAttribute('data-id', modalId)
              showModal(modalId)
            },
            false
          )
        })
        closeTrgArr.forEach(function (target) {
          // クリックイベント
          target.addEventListener(
            'click',
            function (event) {
              const target = event.currentTarget
              let modalId = target.getAttribute('data-close')
              closeModal(modalId)
            },
            false
          )
        })

        // クリックイベント
        overlay.addEventListener(
          'click',
          function (event) {
            const target = event.target
            const modalId = target.getAttribute('data-id')
            closeModal(modalId)
          },
          false
        )
      }
    })
  }
}
