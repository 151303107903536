'use strict'

require('picturefill')
require('intersection-observer')

import { Smooth } from './lib/Smooth'
import { Svg } from './lib/Svg'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { IeModal } from './lib/IeModal'
import { WindowStatus } from './lib/WindowStatus'
import { ViewportHeight } from './lib/ViewportHeight'
import { Bg } from './lib/Bg'
import { WorksBg } from './lib/WorksBg'
import { footerBg } from './lib/footerBg'
import { Accordion } from './lib/Accordion'
import { Progress } from './lib/Progress'
import { Navi } from './lib/Navi'
import { WorksSort } from './lib/WorksSort'
import { Modal } from './lib/Modal'
import { Loading } from './lib/Loading'
import { Pointer } from './lib/Pointer'
import { VideoStart } from './lib/VideoStart'

new IeModal('サイト名', 'サイトURL')
new WindowStatus()
new ViewportHeight()
new Svg()
new ScrollAnimation()
new Smooth()
new Bg()
new WorksBg()
new footerBg()
new Accordion()
new Progress()
new Navi()
new WorksSort()
new Modal()
new Loading()
new Pointer()
new VideoStart()
