export class Bg {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementsByClassName('js-bg_change').length == 0) return
      const boxes = document.getElementsByClassName('js-bg_change')
      const node = Array.prototype.slice.call(boxes, 0)
      const defaultMargin = '-20%' // ここでデフォルトを書き換え
      const keys = []
      const elements = {}
      const kv = document.querySelector('.p-index_kv')

      const doWhenIntersect = function (entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.getAttribute('data-bgcolor') === 'black') {
              document.documentElement.style.setProperty('--bg-color', 'rgba(0,0,0,0.7)')
              document.documentElement.style.setProperty('--color', '#ffffff')
              kv.classList.add('is-hidden')
              document.getElementById('js-kv_movie').classList.add('is-act')
              document.getElementById('js-footer_movie').classList.remove('is-act')
            } else if (entry.target.getAttribute('data-bgcolor') === 'white') {
              document.documentElement.style.setProperty('--bg-color', 'rgba(255,255,255,0)')
              document.documentElement.style.setProperty('--color', '#343434')
            }
          } else {
            document.documentElement.style.setProperty('--bg-color', '#ffffff')
            document.documentElement.style.setProperty('--color', '#343434')
            kv.classList.remove('is-hidden')
            document.getElementById('js-kv_movie').classList.remove('is-act')
            document.getElementById('js-footer_movie').classList.add('is-act')
          }
        })
      }

      node.forEach(function (box) {
        let key = box.getAttribute('data-margin')
        if (key !== null && key !== '' && key.match(/-*[0-9]*%/)) {
          if (key.replace('%', '') - 0 < -50) {
            key = '-50%'
            box.setAttribute('data-margin', key)
          }
          if (keys.indexOf(key) == -1) {
            keys.push(key)
            elements[key] = []
          }
          elements[key].push(box)
        } else {
          if (keys.indexOf(defaultMargin) == -1) {
            keys.push(defaultMargin)
            elements[defaultMargin] = []
          }
          elements[defaultMargin].push(box)
        }
      })

      keys.forEach(function (key) {
        const observer = new IntersectionObserver(doWhenIntersect, { root: null, rootMargin: key + ' 0px', threshold: 0 })
        elements[key].forEach(function (element) {
          observer.observe(element)
        })
      })
    })
  }
}
