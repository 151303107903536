import { gsap } from 'gsap'

export class Navi {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      const elm = document.querySelector('.l-footer')
      const options = {
        root: null,
        rootMargin: '-35% 0px',
        threshold: 0,
      }
      const observer = new IntersectionObserver(doWhenIntersect, options)
      observer.observe(elm)

      function doWhenIntersect(entries) {
        if (window.pageYOffset > 0) {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              document.documentElement.style.setProperty('--color', '#ffffff')
              gsap.to('#js-header_navi', { autoAlpha: 0 })
            } else {
              document.documentElement.style.setProperty('--color', '#343434')
              gsap.to('#js-header_navi', { autoAlpha: 1 })
            }
          })
        }
      }
    })
  }
}
